import React from 'react';
import { Box, Typography } from '@mui/material';
import Layout from '../../../../components/Layout';
import AppBreadcrumbs from '../../../../components/AppBreadcrumbs';
import PDFViewer from '../../../../components/PDFViewer';
import { StyledStickyBreadcrumbBox } from '../../TeacherToolbox.styled';
import { SHARED_BUCKET_URL } from '../../../../variables/constant';
import { ScoreSchemeTitleKeys } from '../../../../variables/types';
import useRubricIndicatorAndStandards from '../rubricIndicatorStandards-hook';

const breadcrumb = [{
  label: 'Toolbox',
  route: '/toolbox',
},
{
  label: 'Rubric, Indicators, & standards',
  route: '/toolbox/rubric-indicators-standards',
},
{
  label: 'standards overview',
}];

const StandardsOverviewView = () => {
  const { user } = useRubricIndicatorAndStandards();
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <StyledStickyBreadcrumbBox>
          <Typography variant="pageTitle">Standards Overview</Typography>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
        </StyledStickyBreadcrumbBox>
        <Box>
          <PDFViewer
            downloadFile
            pdfLink={
              (user?.score_scheme.title_key === ScoreSchemeTitleKeys.Scheme1)
                ? `${SHARED_BUCKET_URL}/pdfs/TexasScoring/StandardsOverview.pdf` : `${SHARED_BUCKET_URL}/pdfs/StandardScoring/StandardOverview.pdf`
            }
            downloadLink="StandardsOverview.pdf"
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default React.memo(StandardsOverviewView);
